
import { defineComponent, onMounted, ref, inject } from "vue";

// components
import Badge from "@/components/atoms/Badge.atoms.vue";

// services
import projectService from "@/services/project.service";

// interfaces
import { IProject } from "@/interfaces/project";

// composables
import useProjectStatusBadge from "@/composables/projectStatusBadge";
import useDateFormatter from "@/composables/dateFormatter";

export default defineComponent({
  components: { Badge },
  setup() {
    const setPageTitle = inject("setPageTitle") as CallableFunction;
    onMounted(() => setPageTitle("Projek Saya"));

    const projects = ref<IProject[]>([]);
    const isLoading = ref(true);
    const page = ref(1);
    const lastPage = ref(0);
    onMounted(async () => {
      isLoading.value = true;
      const { data, last_page } = await projectService.getProjects();
      projects.value = data;
      lastPage.value = last_page;
      isLoading.value = false;
    });

    const { getBadgeVariant } = useProjectStatusBadge();
    const { formatDateTime } = useDateFormatter();
    const token = inject("token");

    const loadMore = async () => {
      page.value += 1;
      const { data, last_page } = await projectService.getProjects(page.value);
      projects.value = [...projects.value, ...data];
      lastPage.value = last_page;
    };

    return {
      token,
      projects,
      getBadgeVariant,
      formatDateTime,
      loadMore,
      lastPage,
      page,
      isLoading,
    };
  },
});
