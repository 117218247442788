import { ProjectStatusEnum } from "@/interfaces/project";

export default function useProjectStatusBadge(): any {
  // get badge class by status
  const getBadgeVariant = (status: string): string => {
    const badgeStatus: { [key: string]: string } = {
      [ProjectStatusEnum.Draft]: "light-gray",
      [ProjectStatusEnum.NotStarted]: "gray",
      [ProjectStatusEnum.OnGoing]: "blue",
      [ProjectStatusEnum.Done]: "green",
    };

    return badgeStatus[status] || "gray";
  };

  return {
    getBadgeVariant,
  };
}
